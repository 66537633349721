import relatorios from './relatorios'

export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/gestor/dashboard',
  },
  {
    icon: 'mdi-cash',
    title: 'Cronograma Financeiro',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Cronograma Financeiro',
        to: 'financeiro',
      },
      {
        icon: 'mdi-car',
        title: 'Lançamento de percurso',
        to: 'odometer',
      },
    ],
  },
  {
    icon: 'mdi-account-multiple',
    title: 'Pessoas',
    group: '/gestor',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Consultores',
        to: 'consultores',
      },
      {
        icon: 'mdi-home-flood',
        title: 'Fazendas',
        to: 'fazendas',
      },
      {
        icon: 'mdi-account',
        title: 'Prestadores',
        to: 'prestador',
      },
      {
        icon: 'mdi-account-cowboy-hat',
        title: 'Produtores',
        to: 'produtores',
      },
      {
        icon: 'mdi-account-cog',
        title: 'Supervisor',
        to: 'central',
      },
    ],
  },
  {
    icon: 'mdi-clipboard-list',
    title: 'Relatórios',
    group: '/gestor/relatorios',
    children: [
      {
        title: 'Gerenciais',
        group: '/gestor/relatorios',
        children: [
          {
            icon: 'mdi-file-document-edit',
            title: 'Visitas por Consultor',
            to: 'visitas',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Deslocamento de Consultores',
            to: 'deslocamento',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Sincronização de Visitas',
            to: 'sinc',
          },
          {
            icon: 'mdi-file-document-edit',
            title: 'Entrega de Material',
            to: 'entrega',
          },
        ],
      },
      {
        subicon: 'mdi-clipboard-list',
        title: 'Visitas',
        group: '/gestor/relatorios',
        children: [
          ...relatorios,
          {
            icon: 'mdi-clipboard-multiple',
            title: 'Entregas de Material',
            to: 'entregas',
          },
        ],
      },
    ],
  },
]
