import store from '@/vuex/store'

let relatorios = []

store.watch(
  function (state) {
    let localReports = JSON.parse(localStorage.getItem('reportsTypes') ?? '[]')

    if (localReports.length) {
      localReports = localReports.map(item => {
        return {
          id: item.id,
          title: item.name,
          icon: 'mdi-clipboard-list',
          to: item.id,
        }
      })
    }

    if (state.user?.permissions.length > 0) {
      relatorios = localReports.filter(item =>
        state.user.permissions.find(
          p => Number(p.report_id) === Number(item.id),
        ),
      )
    } else {
      const user = JSON.parse(localStorage.getItem('user'))

      if (user.permissions.length > 0) {
        relatorios = localReports.filter(item =>
          user.permissions.find(p => Number(p.report_id) === Number(item.id)),
        )
      } else {
        relatorios = localReports
      }
    }
  },
  {
    deep: true,
  },
)

export default relatorios
